import React from "react";
import { IconProps } from "../icon";

export const GitCommitIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 12a4 4 0 01-8 0m8 0a4 4 0 00-8 0m8 0h6M8 12H2"
    />
  </svg>
);
