import React from "react";
import { IconProps } from "../icon";

export const DataIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21.2 22c.28 0 .42 0 .527-.055a.5.5 0 00.218-.218C22 21.62 22 21.48 22 21.2V10.8c0-.28 0-.42-.055-.527a.5.5 0 00-.218-.218C21.62 10 21.48 10 21.2 10h-2.4c-.28 0-.42 0-.527.055a.5.5 0 00-.218.218C18 10.38 18 10.52 18 10.8v2.4c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C17.62 14 17.48 14 17.2 14h-2.4c-.28 0-.42 0-.527.055a.5.5 0 00-.218.218C14 14.38 14 14.52 14 14.8v2.4c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C13.62 18 13.48 18 13.2 18h-2.4c-.28 0-.42 0-.527.055a.5.5 0 00-.218.218C10 18.38 10 18.52 10 18.8v2.4c0 .28 0 .42.055.527a.5.5 0 00.218.218c.107.055.247.055.527.055h10.4zM10 6.8c0-.28 0-.42.055-.527a.5.5 0 01.218-.218C10.38 6 10.52 6 10.8 6h2.4c.28 0 .42 0 .527.054a.5.5 0 01.218.219C14 6.38 14 6.52 14 6.8v2.4c0 .28 0 .42-.055.527a.5.5 0 01-.218.218C13.62 10 13.48 10 13.2 10h-2.4c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218C10 9.62 10 9.48 10 9.2V6.8zM3 12.8c0-.28 0-.42.054-.527a.5.5 0 01.219-.218C3.38 12 3.52 12 3.8 12h2.4c.28 0 .42 0 .527.055a.5.5 0 01.218.218C7 12.38 7 12.52 7 12.8v2.4c0 .28 0 .42-.054.527a.5.5 0 01-.219.218C6.62 16 6.48 16 6.2 16H3.8c-.28 0-.42 0-.527-.055a.5.5 0 01-.219-.218C3 15.62 3 15.48 3 15.2v-2.4zM2 2.8c0-.28 0-.42.054-.527a.5.5 0 01.219-.219C2.38 2 2.52 2 2.8 2h2.4c.28 0 .42 0 .527.054a.5.5 0 01.218.219C6 2.38 6 2.52 6 2.8v2.4c0 .28 0 .42-.054.527a.5.5 0 01-.219.218C5.62 6 5.48 6 5.2 6H2.8c-.28 0-.42 0-.527-.054a.5.5 0 01-.219-.219C2 5.62 2 5.48 2 5.2V2.8z"
    />
  </svg>
);
