import React from "react";
import { IconProps } from "../icon";

export const Dataflow_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 4v11.2c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C14.28 20 15.12 20 16.8 20h.2m0 0a2 2 0 104 0 2 2 0 00-4 0zM7 4h10M7 4a2 2 0 11-4 0 2 2 0 014 0zm10 0a2 2 0 104 0 2 2 0 00-4 0zm-5 8h5m0 0a2 2 0 104 0 2 2 0 00-4 0z"
    />
  </svg>
);
