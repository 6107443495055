import React from "react";
import { IconProps } from "../icon";

export const GitMergeIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 18a3 3 0 106 0 3 3 0 00-6 0zm0 0a9 9 0 01-9-9m0 0a3 3 0 100-6 3 3 0 000 6zm0 0v12"
    />
  </svg>
);
