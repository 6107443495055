import React from "react";
import { IconProps } from "../icon";

export const Server_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 10.5l-.474-3.795c-.186-1.489-.28-2.233-.63-2.794a3 3 0 00-1.283-1.133c-.6-.278-1.35-.278-2.85-.278H7.237c-1.5 0-2.25 0-2.85.278a3 3 0 00-1.283 1.133c-.35.56-.444 1.305-.63 2.794L2 10.5m3.5 4h13m-13 0a3.5 3.5 0 110-7h13a3.5 3.5 0 110 7m-13 0a3.5 3.5 0 100 7h13a3.5 3.5 0 100-7M6 11h.01M6 18h.01M12 11h6m-6 7h6"
    />
  </svg>
);
