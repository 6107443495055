import React from "react";
import { IconProps } from "../icon";

export const Server_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 17.5l-.692-10.03c-.108-1.575-.163-2.362-.504-2.96a3 3 0 00-1.298-1.21C18.887 3 18.098 3 16.52 3H7.48c-1.578 0-2.367 0-2.986.3a3 3 0 00-1.298 1.21c-.341.598-.396 1.385-.504 2.96L2 17.5m20 0a3.5 3.5 0 01-3.5 3.5h-13A3.5 3.5 0 012 17.5m20 0a3.5 3.5 0 00-3.5-3.5h-13A3.5 3.5 0 002 17.5m4 0h.01m5.99 0h6"
    />
  </svg>
);
