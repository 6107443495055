import React from "react";
import { IconProps } from "../icon";

export const QrCode_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.5 6.5h.01m10.99 0h.01M6.5 17.5h.01M13 13h.01m4.49 4.5h.01M17 21h4v-4m-7-.5V21m7-7h-4.5m-.9-4h3.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C21 9.24 21 8.96 21 8.4V4.6c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C20.24 3 19.96 3 19.4 3h-3.8c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C14 3.76 14 4.04 14 4.6v3.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C14.76 10 15.04 10 15.6 10zm-11 0h3.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C10 9.24 10 8.96 10 8.4V4.6c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C9.24 3 8.96 3 8.4 3H4.6c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C3 3.76 3 4.04 3 4.6v3.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C3.76 10 4.04 10 4.6 10zm0 11h3.8c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C10 20.24 10 19.96 10 19.4v-3.8c0-.56 0-.84-.109-1.054a1 1 0 00-.437-.437C9.24 14 8.96 14 8.4 14H4.6c-.56 0-.84 0-1.054.109a1 1 0 00-.437.437C3 14.76 3 15.04 3 15.6v3.8c0 .56 0 .84.109 1.054a1 1 0 00.437.437C3.76 21 4.04 21 4.6 21z"
    />
  </svg>
);
