import React from "react";
import { IconProps } from "../icon";

export const Server_05Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9a7 7 0 01-7 7m7-7a7 7 0 00-7-7m7 7H5m7 7a7 7 0 01-7-7m7 7a10.71 10.71 0 002.8-7A10.711 10.711 0 0012 2m0 14a10.709 10.709 0 01-2.8-7A10.709 10.709 0 0112 2m0 14v2M5 9a7 7 0 017-7m2 18a2 2 0 11-4 0m4 0a2 2 0 00-2-2m2 2h7m-11 0a2 2 0 012-2m-2 2H3"
    />
  </svg>
);
