import React from "react";
import { IconProps } from "../icon";

export const Dataflow_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 18v-.2c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C6.28 13 7.12 13 8.8 13h6.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C20 15.28 20 16.12 20 17.8v.2M4 18a2 2 0 100 4 2 2 0 000-4zm16 0a2 2 0 100 4 2 2 0 000-4zm-8 0a2 2 0 100 4 2 2 0 000-4zm0 0V8M6 8h12c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C21 6.398 21 5.932 21 5c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C19.398 2 18.932 2 18 2H6c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C3 3.602 3 4.068 3 5c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C4.602 8 5.068 8 6 8z"
    />
  </svg>
);
