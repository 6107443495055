import React from "react";
import { IconProps } from "../icon";

export const PuzzlePieceIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7.5 4.5a2.5 2.5 0 015 0V6h1c1.398 0 2.097 0 2.648.228a3 3 0 011.624 1.624C18 8.403 18 9.102 18 10.5h1.5a2.5 2.5 0 010 5H18v1.7c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C15.72 22 14.88 22 13.2 22h-.7v-1.75a2.25 2.25 0 00-4.5 0V22H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311C2 19.72 2 18.88 2 17.2v-1.7h1.5a2.5 2.5 0 000-5H2c0-1.398 0-2.097.228-2.648a3 3 0 011.624-1.624C4.403 6 5.102 6 6.5 6h1V4.5z"
    />
  </svg>
);
